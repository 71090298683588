import React, { FC } from 'react';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import { IRememberProps } from '../../model';

const RememberItemsList: FC<Pick<IRememberProps, 'rememberItems'>> = ({ rememberItems }) => (
  <ul className="remember__list">
    {rememberItems.map(({ properties: { title, description, letter, itemBg, icon, iconAlt } }) => {
      const { label } = itemBg?.[0]?.properties?.colorPicker || {};

      return (
        <li
          key={title}
          className={classnames('remember__list-item', {
            [`${label}-default-bg`]: label,
          })}
        >
          <DangerouslySetInnerHtml className="remember__list-item-title" html={title} />
          <p className="remember__list-item-letter">{letter}</p>
          <UniversalImage wrapperClassName="remember__list-item-image" img={icon} alt={iconAlt} />
          <DangerouslySetInnerHtml className="remember__list-item-description" html={description} />
        </li>
      );
    })}
  </ul>
);

export default RememberItemsList;
