import React from "react"

import ArticleSubNav from "components/ArticleSubNav"
import PainBanner from "components/PainBanner"
import Pledge from "components/Pledge"
import Facts from "components/Facts"
import Reports from "components/Reports"
import PainBannerExtendTitle from "components/PainBannerExtendTitle"
import VideoIframeSection from "components/VideoIframeSection/VideoIframeSection"
import RteSection from "components/RteSection/RteSection"
import AnchorSubmenu from "components/AnchorSubmenu"
import PainPassBanner from "components/PainPassBanner/PainPassBanner"
import RememberSection from "components/RememberSection"
import ScaleSection from "components/ScaleSection/ScaleSection"

import { formatAnchor } from "utils/helpers"
import ReadReportSection from "components/ReadReportSection"

const bodySections: AppPageDataTypes.TBodyElements = {
  "Pain Banner": ({ title, bannerImage, imageAlt }, keyId) => (
    <PainBanner
      key={keyId}
      title={title}
      image={bannerImage}
      imageAlt={imageAlt}
    />
  ),
  "Article Anchor Navigation": (
    { title, description, sectionAnchors, bulletColor },
    keyId
  ) => (
    <ArticleSubNav
      key={keyId}
      colWidth={{ md: 11 }}
      title={title}
      description={description}
      sections={sectionAnchors}
      bulletColor={bulletColor[0].properties.colorPicker?.label || "red"}
    />
  ),
  "Facts Section": (
    {
      title,
      description,
      sectionBg,
      sectionTheme,
      facts,
      sectionAnchor,
      anchorText,
      fileLink,
    },
    keyId
  ) => (
    <Facts
      key={keyId}
      title={title}
      description={description}
      facts={facts}
      anchorText={anchorText}
      fileLink={fileLink}
      sectionBg={sectionBg}
      sectionTheme={sectionTheme}
      sectionAnchor={formatAnchor(sectionAnchor)}
    />
  ),
  "Reports Section": (
    {
      title,
      description,
      reports,
      sectionAnchor,
      additionalAnchor,
      sectionBackground,
      sectionTheme,
      showCarouselOnMobile,
      shareBlock,
      isActiveWithReturning,
    },
    keyId
  ) => (
    <Reports
      key={keyId}
      title={title}
      description={description}
      reports={reports}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
      sectionTheme={sectionTheme}
      showCarouselOnMobile={showCarouselOnMobile}
      shareBlock={shareBlock}
      additionalAnchor={formatAnchor(additionalAnchor)}
      isActiveWithReturning={isActiveWithReturning}
    />
  ),

  "Read Report Section": (
    {
      title,
      rteTitle,
      description,
      sectionAnchor,
      image,
      imageAlt,
      imageDescription,
      imageBackground,
      anchorText,
      link,
      fileLink,
      icon,
      sectionBackground,
      sectionTheme,
      note,
      words,
      thingsList,
    },
    keyId
  ) => (
    <ReadReportSection
      key={keyId}
      title={title}
      rteTitle={rteTitle}
      description={description}
      sectionAnchor={formatAnchor(sectionAnchor)}
      image={image}
      imageAlt={imageAlt}
      imageDescription={imageDescription}
      imageBackground={imageBackground}
      anchorText={anchorText}
      link={link}
      fileLink={fileLink}
      icon={icon}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
      note={note}
      words={words}
      thingsList={thingsList}
    />
  ),
  "Pledge Section": (
    {
      title,
      description,
      items,
      sectionAnchor,
      sectionBackground,
      sectionTheme,
      showCarouselOnMobile,
    },
    keyId
  ) => (
    <Pledge
      key={keyId}
      title={title}
      description={description}
      items={items}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
      sectionTheme={sectionTheme}
      showCarouselOnMobile={showCarouselOnMobile}
    />
  ),
  "Pain Banner Extend Title": (
    {
      title,
      header,
      headerSmall,
      image,
      imageMobile,
      imageAlt,
      buttonLink,
      text,
      sectionTheme,
    },
    keyId
  ) => (
    <PainBannerExtendTitle
      key={keyId}
      title={title}
      header={header}
      headerSmall={headerSmall}
      image={image}
      imageMobile={imageMobile}
      imageAlt={imageAlt}
      buttonLink={buttonLink}
      text={text}
      sectionTheme={sectionTheme}
    />
  ),
  "Video Iframe Section": (
    {
      title,
      subtitle,
      description,
      videoIframe,
      readMoreButton,
      readMoreButtonAriaLabel,
      sectionTheme,
      sectionAnchor,
    },
    keyId
  ) => (
    <VideoIframeSection
      key={keyId}
      title={title}
      videoIframe={videoIframe}
      subtitle={subtitle}
      description={description}
      readMoreButton={readMoreButton}
      readMoreButtonAriaLabel={readMoreButtonAriaLabel}
      sectionTheme={sectionTheme}
      sectionAnchor={formatAnchor(sectionAnchor)}
    />
  ),
  "Rte Section": ({ text, sectionTheme, sectionBackground }, keyId) => (
    <RteSection
      key={keyId}
      text={text}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
    />
  ),

  "Anchor Submenu": ({ sectionAnchors, sectionBg, sectionTheme }, keyId) => (
    <AnchorSubmenu
      key={keyId}
      sectionBg={sectionBg}
      sections={sectionAnchors}
      sectionTheme={sectionTheme}
    />
  ),
  "Pain Pass Banner": (
    {
      title,
      cta,
      painPassBannerimage,
      image,
      backgroundImage,
      mobileBackgroundImage,
      withAnchorNavigation,
      imageAlt,
      sectionAnchors,
    },
    keyId
  ) => (
    <PainPassBanner
      key={keyId}
      title={title}
      image={image}
      imageAlt={imageAlt}
      cta={cta}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      sections={sectionAnchors}
      withAnchorNavigation={withAnchorNavigation}
    />
  ),
  "Remember Section": (
    { sectionAnchor, title, description, rememberItems },
    keyId
  ) => (
    <RememberSection
      key={keyId}
      //sectionAnchor={formatAnchor(sectionAnchor)}
      title={title}
      description={description}
      rememberItems={rememberItems}
    />
  ),
  "Scale Section": (
    { sectionAnchor, title, description, sectionBg, scale },
    keyId
  ) => (
    <ScaleSection
      key={keyId}
      sectionAnchor={formatAnchor(sectionAnchor)}
      title={title}
      description={description}
      sectionBg={sectionBg}
      scale={scale}
    />
  ),
}

export default bodySections
