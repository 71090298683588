import React, { FC, useEffect } from "react"
import { graphql } from "gatsby"
import classnames from "classnames"

import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml"
import useScreenRecognition from "hooks/useScreenRecognition"
import { scrollToTarget } from "utils/browser"

import CarouselReports from "./components/CarouselReports"
import ListReports from "./components/ListReports"
import ShareBlock from "./components/ShareBlock"
import { IReportsProps } from "./model"

import "./Reports.scss"

const Reports: FC<IReportsProps> = ({
  title,
  description,
  reports,
  sectionAnchor,
  additionalAnchor,
  sectionBackground,
  sectionTheme,
  showCarouselOnMobile,
  shareBlock,
  isActiveWithReturning,
}) => {
  const { isMobile } = useScreenRecognition()

  useEffect(() => {
    const hasPreviousLink = Boolean(
      reports.find(
        report => window.previousPath === report?.properties?.cta?.[0]?.url
      )
    )

    if (sectionAnchor && isActiveWithReturning && hasPreviousLink) {
      scrollToTarget(sectionAnchor?.toString())
    }
  }, [])

  return (
    <section
      className={classnames("reports", {
        [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
          ?.properties?.colorPicker?.label,
        [`${sectionTheme}`]: sectionTheme,
      })}
      id={sectionAnchor}
    >
      <div className="reports__container">
        <div className="reports__header">
          {title ? <h2 className="reports__header-title">{title}</h2> : null}
          <DangerouslySetInnerHtml
            className="reports__header-description"
            html={description}
          />
        </div>
        {showCarouselOnMobile && isMobile ? (
          <CarouselReports reports={reports} />
        ) : (
          <ListReports reports={reports} />
        )}
        {shareBlock?.length ? (
          <ShareBlock shareBlock={shareBlock} anchorId={additionalAnchor} />
        ) : null}
      </div>
    </section>
  )
}

export default Reports
