import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import RememberItemsList from './components/RememberItemsList';
import RememberItemsAccordion from './components/RememberItemsAccordion/RememberItemsAccordion';
import { IRememberProps } from './model';

import './RememberSection.scss';

const RememberSection: FC<IRememberProps> = ({
  sectionAnchor,
  title,
  description,
  rememberItems,
}) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 992px)');

    function updateIsDesktop() {
      setIsDesktop(mediaQuery.matches);
    }
    updateIsDesktop();
    mediaQuery.addListener(updateIsDesktop);

    return () => mediaQuery.removeListener(updateIsDesktop);
  }, []);

  return (
    <section className="remember" id={sectionAnchor}>
      <div className="remember__container">
        <DangerouslySetInnerHtml className="remember__title" html={title} />
        <DangerouslySetInnerHtml className="remember__description" html={description} />
        {isDesktop ? (
          <RememberItemsList rememberItems={rememberItems} />
        ) : (
          <RememberItemsAccordion rememberItems={rememberItems} />
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentRememberSection on IRemember {
    title
    description
    rememberItems {
      properties {
        title
        letter
        description
        itemBg {
          properties {
            colorPicker {
              label
            }
          }
        }
        icon {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(height: 70, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default RememberSection;
