import React, { FC } from 'react';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPledgeItems } from '../../model';

const ListPledges: FC<IPledgeItems> = ({ items }) => (
  <ul className="pledge__list">
    {items.map(
      ({ properties: { title: itemTitle, description: itemDescription, image, imageAlt } }) => (
        <li className="pledge__list-item" key={itemTitle}>
          <div className="pledge__list-item-text">
            <p className="pledge__list-item-title">{itemTitle}</p>
            <DangerouslySetInnerHtml
              className="pledge__list-item-description"
              html={itemDescription}
            />
          </div>

          {image ? (
            <div className="pledge__list-item-image">
              <UniversalImage img={image} imageAlt={imageAlt} />
            </div>
          ) : null}
        </li>
      )
    )}
  </ul>
);

export default ListPledges;
