const responsiveObj = {
  l: {
    breakpoint: { max: 990, min: 591 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  m: {
    breakpoint: { max: 590, min: 541 },
    items: 1,
    partialVisibilityGutter: 240,
  },
  sm: {
    breakpoint: { max: 540, min: 491 },
    items: 1,
    partialVisibilityGutter: 190,
  },
  s: {
    breakpoint: { max: 490, min: 426 },
    items: 1,
    partialVisibilityGutter: 130,
  },
  xs: {
    breakpoint: { max: 425, min: 350 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

export { responsiveObj as default };
