import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IRememberProps } from '../../model';

const RememberItemsAccordion: FC<Pick<IRememberProps, 'rememberItems'>> = ({ rememberItems }) => (
  <Accordion className="remember__accordion" openClassName="remember__accordion-item--open">
    {rememberItems.map(({ properties: { title, description, letter, itemBg } }) => {
      const { label } = itemBg?.[0]?.properties?.colorPicker || {};

      return (
        <div
          key={title}
          className={classnames('remember__accordion-item-content', {
            [`${label}-default-bg`]: label,
          })}
          data-header={
            <button
              type="button"
              className={classnames('remember__accordion-item-header', {
                [`${label}-default-bg`]: label,
              })}
            >
              <p className="remember__accordion-item-letter">{letter}</p>
              <DangerouslySetInnerHtml className="remember__accordion-item-title" html={title} />
              <span
                aria-hidden="true"
                className="nf-icon-custom icon-arrow_forward_ios remember__accordion-item--icon"
              />
            </button>
          }
        >
          <DangerouslySetInnerHtml
            className="remember__accordion-item-description"
            html={description}
          />
        </div>
      );
    })}
  </Accordion>
);

export default RememberItemsAccordion;
