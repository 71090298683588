import React, { FC } from 'react';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';

import { IShareBlock } from '../../model';

const ShareBlock: FC<IShareBlock> = ({ shareBlock, anchorId }) => {
  const { title, description, counter, counterDescription, link, image, imageAlt } =
    shareBlock?.[0]?.properties || {};
  const { name, url, target } = link?.[0] || {};
  const numbers = (counter || '').split('');

  return (
    <div className="reports__share" id={anchorId}>
      <div className="reports__share-visual-holder">
        <UniversalImage wrapperClassName="reports__share-image" img={image} imageAlt={imageAlt} />

        {numbers?.length ? (
          <div className="reports__share-counter-holder">
            <div className="reports__share-counter">
              {numbers.map((number, idx) => (
                <span key={number + idx} className="reports__share-counter-item">
                  {number}
                </span>
              ))}
            </div>
            <div className="reports__share-counter-description">{counterDescription}</div>
          </div>
        ) : null}
      </div>

      <div className="reports__share-text-holder">
        <DangerouslySetInnerHtml className="reports__share-title" html={title} />
        <DangerouslySetInnerHtml className="reports__share-description" html={description} />
        {name && url ? (
          <NFButton btnText={name} link={url} target={target} className="reports__share-link" />
        ) : null}
      </div>
    </div>
  );
};

export default ShareBlock;
