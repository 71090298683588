import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IScaleProps } from './model';

import './ScaleSection.scss';

const ScaleSection: FC<IScaleProps> = ({ sectionAnchor, title, description, sectionBg, scale }) => {
  const { label } = sectionBg?.[0]?.properties?.colorPicker || {};

  return (
    <section
      id={sectionAnchor}
      className={classnames('scale', {
        [`${label}-default-bg`]: label,
      })}
    >
      <div className="scale__container">
        <DangerouslySetInnerHtml className="scale__title" html={title} />
        <DangerouslySetInnerHtml className="scale__description" html={description} />

        <ul className="scale__list">
          {scale.map(({ properties: { title: scaleTitle, scaleBg, label: scaleLabel } }, idx) => {
            const { label: scaleBgLabel } = scaleBg?.[0]?.properties?.colorPicker || {};

            return (
              <li key={scaleLabel} className="scale__list-item">
                <p className="scale__list-item-title">{scaleTitle}</p>
                <span
                  className={classnames('scale__list-item-number', {
                    [`${scaleBgLabel}-default-bg`]: scaleBgLabel,
                  })}
                >
                  {idx + 1}
                </span>
                <p className="scale__list-item-label">{scaleLabel}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentScale on IScale {
    sectionAnchor
    title
    description
    sectionBg {
      properties {
        colorPicker {
          label
        }
      }
    }
    scale {
      properties {
        title
        scaleBg {
          properties {
            colorPicker {
              label
            }
          }
        }
        label
      }
    }
  }
`;

export default ScaleSection;
