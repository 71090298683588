import React, { FC } from 'react';
import { Link } from 'gatsby';

import ControlledCarousel from 'components/ControlledCarousel';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import responsiveObj from './constants';

import { IReports } from '../../model';

const CarouselReports: FC<IReports> = ({ reports }) => (
  <ControlledCarousel classes="reports__carousel" responsiveObj={responsiveObj}>
    {reports?.map(
      ({
        properties: {
          title: reportTitle,
          description: reportDescription,
          cta,
          ctaAria,
          image,
          imageAlt,
        },
      }) => (
        <Link
          className="reports__list-item"
          key={reportTitle}
          to={cta?.[0]?.url}
          aria-label={ctaAria}
        >
          <div className="reports__item-image">
            <UniversalImage img={image} imageAlt={imageAlt} />
          </div>

          <div className="reports__item-text">
            <p className="reports__item-title">{reportTitle}</p>
            <DangerouslySetInnerHtml
              html={reportDescription}
              className="reports__item-description"
            />
          </div>
        </Link>
      )
    )}
  </ControlledCarousel>
);

export default CarouselReports;
