import React, { FC } from "react"
import { graphql } from "gatsby"
import classnames from "classnames"

import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml"
import useScreenRecognition from "hooks/useScreenRecognition"

import { IPledgeProps } from "./model"

import "./Pledge.scss"
import ListPledge from "./components/ListPledges"
import CarouselPledges from "./components/CarouselPledges"

const Pledge: FC<IPledgeProps> = ({
  title,
  description,
  items,
  sectionAnchor,
  sectionBackground: [
    {
      properties: { colorPicker },
    },
  ],
  sectionTheme,
  showCarouselOnMobile,
}) => {
  const { isMobile } = useScreenRecognition()

  return (
    <section
      className={classnames("pledge", {
        [`${colorPicker?.label}-default-bg`]: colorPicker?.label,
        [`${sectionTheme}`]: sectionTheme,
      })}
      id={sectionAnchor}
    >
      <div className="pledge__container">
        <div className="pledge__header">
          <h2 className="pledge__title">{title}</h2>
          <DangerouslySetInnerHtml
            html={description}
            className="pledge__description"
          />
        </div>

        {showCarouselOnMobile && isMobile ? (
          <CarouselPledges items={items} />
        ) : (
          <ListPledge items={items} />
        )}
      </div>
    </section>
  )
}

export default Pledge
