import React, { FC } from 'react';
import { Link } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IReports } from '../../model';

const ListReports: FC<IReports> = ({ reports }) => (
  <ul className="reports__list">
    {reports?.map(
      ({
        properties: {
          title: reportTitle,
          description: reportDescription,
          cta,
          ctaAria,
          image,
          imageAlt,
        },
      }) => (
        <li className="reports__list-item" key={reportTitle}>
          <Link
            to={cta?.[0]?.url}
            className="reports__list-item--link"
            aria-label={ctaAria}
            key={reportTitle}
          >
            <div className="reports__item-image">
              <UniversalImage img={image} imageAlt={imageAlt} />
            </div>

            <div className="reports__item-text">
              <p className="reports__item-title">{reportTitle}</p>
              <DangerouslySetInnerHtml
                html={reportDescription}
                className="reports__item-description"
              />
            </div>
          </Link>
        </li>
      )
    )}
  </ul>
);

export default ListReports;
