import React, { FC } from 'react';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IRteSectionProps } from './model';

import './RteSection.scss';

const RteSection: FC<IRteSectionProps> = ({ text, sectionTheme, sectionBackground }) => (
  <section
    className={classnames('rte-section', {
      [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
        ?.properties?.colorPicker?.label,
      [`${sectionTheme}`]: sectionTheme,
    })}
    data-testid="rte-section"
  >
    <div className="rte-section__container">
      <DangerouslySetInnerHtml className="rte-section__text" html={text} />
    </div>
  </section>
);

export default RteSection;
