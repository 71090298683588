const responsiveObj = {
  lg: {
    breakpoint: { max: 990, min: 650 },
    items: 2,
    partialVisibilityGutter: 70,
  },
  l: {
    breakpoint: { max: 650, min: 550 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  m: {
    breakpoint: { max: 550, min: 481 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  sm: {
    breakpoint: { max: 480, min: 441 },
    items: 1,
    partialVisibilityGutter: 150,
  },
  s: {
    breakpoint: { max: 440, min: 415 },
    items: 1,
    partialVisibilityGutter: 120,
  },
  xs: {
    breakpoint: { max: 414, min: 350 },
    items: 1,
    partialVisibilityGutter: 100,
  },
};

export { responsiveObj as default };
